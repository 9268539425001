import { withAsyncContext as _withAsyncContext, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import useNetwork from "@/composables/useNetwork";
import useSaleContract from "@/composables/useSaleContract";
import { FTMSCAN } from "@/constants";
import { useDialog } from "naive-ui";
import { h } from "vue";
import Cta from "./Cta.vue";

export default _defineComponent({
  async setup(__props) {

let __temp:any, __restore:any

const { reservedTokens, redeemTokens, lcdToDistribute } = (([__temp,__restore]=_withAsyncContext(()=>(useSaleContract()))),__temp=await __temp,__restore(),__temp);
const { addLcdToMetamask } = useNetwork();
const dialog = useDialog();
const doRedeemTokens = async () => {
  if (reservedTokens.value <= 0 || lcdToDistribute.value <= 0) {
    return;
  }

  try {
    const res = await redeemTokens();
    showSuccess(res.transactionHash);
    await addLcdToMetamask();
  } catch (error: any) {
    showFail(error.receipt.transactionHash);
  }
};

const showSuccess = (txHash: string) => {
  dialog.success({
    title: "Great!",
    content: () =>
      h("div", [
        "Transaction approved!",
        h("br"),
        h(
          "a",
          {
            href: txHash ? `${FTMSCAN}/tx/${txHash}` : "",
            class: "modal-link_success",
            target: "_blank",
          },
          txHash ? "see transaction" : ""
        ),
      ]),
    positiveText: "Ok",
    closable: false,
    iconPlacement: "top",
    icon: () => h("img", { src: "icons/round-check.svg", alt: "check" }),
  });
};

const showFail = (txHash: string) => {
  dialog.warning({
    title: "Sorry!",
    content: () =>
      h("div", [
        "Transaction failed!",
        h("br"),
        h(
          "a",
          {
            href: txHash ? `${FTMSCAN}/tx/${txHash}` : "",
            class: "modal-link_fail",
            target: "_blank",
          },
          txHash ? "see transaction" : ""
        ),
      ]),
    positiveText: "Ok",
    closable: false,
    iconPlacement: "top",
    icon: () => h("img", { src: "icons/round-cross.svg", alt: "check" }),
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Cta, {
    text: 'Redeem tokens',
    disabled: +_unref(reservedTokens) <= 0 || +_unref(lcdToDistribute) <= 0,
    onClick: doRedeemTokens
  }, null, 8, ["disabled"]))
}
}

})