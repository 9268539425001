import { useStore } from "@/store/store";
import { AbiItem } from "web3-utils";
import LucidaoSaleAbi from "@/../public/abi/LucidaoSale.json";
import LucidaoAbi from "@/../public/abi/Lucidao.json";
import GenericTokenAbi from "@/../public/abi/GenericToken.json";
import { LUCIDAO_SALE_ADDRESS, LUCIDAO_ADDRESS, USDT_ADDRESS } from "@/constants";
import { computed, ref } from "vue";
import BN from "bn.js";

export default async function useSaleContract() {
  const store = useStore();

  const lucidaoSale = new store.getters.eth.Contract(
    LucidaoSaleAbi as AbiItem[],
    LUCIDAO_SALE_ADDRESS
  );
  const lucidao = new store.getters.eth.Contract(
    LucidaoAbi as AbiItem[],
    LUCIDAO_ADDRESS
  );
  const genericToken = new store.getters.eth.Contract(
    GenericTokenAbi as AbiItem[],
    USDT_ADDRESS
  );

  const selectedAccountUsdtBalance = ref("");
  const selectedAccountLucidaoBalance = ref("");
  const contractLucidaoToSell = ref("0");
  const contractLucidaoSold = ref("0");

  const usdtRaised = ref(0.0);
  const usdtAmount = ref(0.0);
  const usdtAmountWei = computed(() => store.getters.utils.toWei(usdtAmount.value.toString(), "picoether"));
  let approveTimeout: NodeJS.Timeout;
  let buyTimeout: NodeJS.Timeout;
  let redeemTimeout: NodeJS.Timeout;

  const [
    maxPersonalCapUnformatted,
    usdtAllowanceToRef,
    tokensForSaleUnformatted,
    contributionToRef,
    rate,
    tokensSoldUnformatted,
    lcdToDistributeToRef
  ] = await Promise.all([
    lucidaoSale.methods.cap().call(),
    genericToken.methods
      .allowance(store.state.currentUser, LUCIDAO_SALE_ADDRESS)
      .call(),
    lucidaoSale.methods.tokensForSale().call(),
    lucidaoSale.methods.contributions(store.state.currentUser).call(),
    lucidaoSale.methods.rate().call(),
    lucidaoSale.methods.tokensSold().call(),
    lucidaoSale.methods.balanceOf(store.state.currentUser).call()
  ]);

  const formatBalance = (balance: string | BN, unit?: string) => unit ? store.getters.utils.fromWei(balance, unit) : store.getters.utils.fromWei(balance);

  const maxPersonalCap = formatBalance(maxPersonalCapUnformatted);
  const maxReservedTokens = +maxPersonalCap * +rate;
  const tokensForSale = formatBalance(tokensForSaleUnformatted);
  const usdtAllowance = ref(usdtAllowanceToRef);
  const contribution = ref(contributionToRef);
  const reservedTokens = ref(formatBalance(store.getters.utils.toBN(contributionToRef).mul(store.getters.utils.toBN(rate))));
  const lcdToDistribute = ref(lcdToDistributeToRef);
  const tokensSpendable = computed(() =>
    formatBalance(
      store.getters.utils
        .toBN(maxPersonalCapUnformatted)
        .sub(store.getters.utils.toBN(contribution.value)),
      "picoether"
    )
  );

  const percentage = computed(
    () => (+contractLucidaoSold.value * 100) / +tokensForSale
  );
  const remainingPercentage = computed(() => 100 - percentage.value);
  const reservedTokensPercentage = computed(
    () => (+reservedTokens.value * 100) / +maxReservedTokens
  );

  contractLucidaoSold.value = formatBalance(tokensSoldUnformatted);
  contractLucidaoToSell.value = formatBalance(
    store.getters.utils
      .toBN(tokensForSaleUnformatted)
      .sub(store.getters.utils.toBN(tokensSoldUnformatted))
  );

  const getSaleChanges = async () => {
    const [
      selectedAccountUsdtBalanceUnformatted,
      selectedAccountLucidaoBalanceUnformatted,
      tokensSoldUnformatted,
      usdtRaisedUnformatted,
      contributionsUnformatted,
    ] = await Promise.all([
      genericToken.methods.balanceOf(store.state.currentUser).call(),
      lucidao.methods.balanceOf(store.state.currentUser).call(),
      lucidaoSale.methods.tokensSold().call(),
      lucidaoSale.methods.weiRaised().call(),
      lucidaoSale.methods.contributions(store.state.currentUser).call(),
    ]);
    selectedAccountUsdtBalance.value = formatBalance(selectedAccountUsdtBalanceUnformatted, "picoether");
    selectedAccountLucidaoBalance.value = formatBalance(
      selectedAccountLucidaoBalanceUnformatted
    );
    contractLucidaoSold.value = formatBalance(tokensSoldUnformatted);
    contractLucidaoToSell.value = formatBalance(
      store.getters.utils
        .toBN(tokensForSaleUnformatted)
        .sub(store.getters.utils.toBN(tokensSoldUnformatted))
    );
    usdtRaised.value = +formatBalance(usdtRaisedUnformatted, "picoether");
    reservedTokens.value = formatBalance(store.getters.utils.toBN(contributionsUnformatted).mul(store.getters.utils.toBN(rate)));
  };

  const approve = async () => {
    const amount = store.getters.utils.toWei(
      maxPersonalCapUnformatted,
      "ether"
    );
    await genericToken.methods
      .approve(LUCIDAO_SALE_ADDRESS, amount)
      .send({ from: store.state.currentUser })
      .on("confirmation", () => {
        if (approveTimeout) {
          clearTimeout(approveTimeout);
        }
        approveTimeout = setTimeout(async () => {
          usdtAllowance.value = await genericToken.methods
            .allowance(store.state.currentUser, LUCIDAO_SALE_ADDRESS)
            .call();
        }, 1000);
      });
  };

  const buyTokens = async () => {
    const response = await lucidaoSale.methods
      .buyTokens(usdtAmountWei.value)
      .send({ from: store.state.currentUser })
      .on("confirmation", () => {
        if (buyTimeout) {
          clearTimeout(buyTimeout);
        }
        buyTimeout = setTimeout(async () => {
          contribution.value = await lucidaoSale.methods
            .contributions(store.state.currentUser)
            .call();
        }, 1000);
      });
    return response;
  };

  const setMaxUsdt = async () => {
    const usdtAvailable = +store.getters.utils.fromWei(
      await genericToken.methods.balanceOf(store.state.currentUser).call(),
      "picoether"
    );
    usdtAmount.value =
      usdtAvailable > +tokensSpendable.value
        ? +tokensSpendable.value
        : usdtAvailable;
  };

  const redeemTokens = async () => {
    const res = await lucidaoSale.methods
      .withdrawTokens()
      .send({ from: store.state.currentUser })
      .on("confirmation", () => {
        if (redeemTimeout) {
          clearTimeout(redeemTimeout);
        }
        redeemTimeout = setTimeout(async () => {
          lcdToDistribute.value = await lucidaoSale.methods.balanceOf(store.state.currentUser).call();
        }, 1000);
      });
  };

  return {
    getSaleChanges,
    contribution,
    tokensSpendable,
    contractLucidaoSold,
    contractLucidaoToSell,
    maxPersonalCap,
    tokensForSale,
    usdtRaised,
    approve,
    buyTokens,
    usdtAmount,
    usdtAllowance,
    percentage,
    remainingPercentage,
    reservedTokensPercentage,
    setMaxUsdt,
    reservedTokens,
    redeemTokens,
    lcdToDistribute
  };
}
