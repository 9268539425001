import { useStore } from "@/store/store";
import LucidaoAbi from "@/../public/abi/Lucidao.json";
import GenericTokenAbi from "@/../public/abi/GenericToken.json";
import { AbiItem } from "web3-utils";
import { USDT_ADDRESS, LUCIDAO_ADDRESS } from "@/constants";
import { ref } from "vue";

export default function useBalances() {
  const store = useStore();

  const lucidao = new store.getters.eth.Contract(
    LucidaoAbi as AbiItem[],
    LUCIDAO_ADDRESS
  );
  const usdt = new store.getters.eth.Contract(
    GenericTokenAbi as AbiItem[],
    USDT_ADDRESS
  );

  const selectedAccountUsdtBalance = ref("");
  const selectedAccountLucidaoBalance = ref("");

  const formatBalance = (balance: string, unit?: string) => unit ? store.getters.utils.fromWei(balance, unit) : store.getters.utils.fromWei(balance);

  const getBalances = async () => {
    const [
      selectedAccountUsdtBalanceUnformatted,
      selectedAccountLucidaoBalanceUnformatted,
    ] = await Promise.all([
      usdt.methods.balanceOf(store.state.currentUser).call(),
      lucidao.methods.balanceOf(store.state.currentUser).call(),
    ]);
    selectedAccountUsdtBalance.value = formatBalance(selectedAccountUsdtBalanceUnformatted, "picoether");
    selectedAccountLucidaoBalance.value = formatBalance(selectedAccountLucidaoBalanceUnformatted);
  };

  return {
    getBalances,
    selectedAccountUsdtBalance,
    selectedAccountLucidaoBalance,
  };
}
