import { LUCIDAO_SALE_ADDRESS } from "@/constants";
import { useStore } from "@/store/store";
import { AbiItem } from "web3-utils";
import LucidaoSaleAbi from "@/../public/abi/LucidaoSale.json";
import { ref } from "vue";

export default async function useSaleSchedule() {
  const store = useStore();

  const lucidaoSale = new store.getters.eth.Contract(
    LucidaoSaleAbi as AbiItem[],
    LUCIDAO_SALE_ADDRESS
  );

  const [openingTime, closingTime, isClosed, rate] = await Promise.all([
    lucidaoSale.methods.openingTime().call(),
    lucidaoSale.methods.closingTime().call(),
    lucidaoSale.methods.isClosed().call(),
    lucidaoSale.methods.rate().call(),
  ]);

  const isOpen = ref(false);

  const rateFormatted = store.getters.utils.fromWei(rate, "microether");

  const checkIsOpen = async () => { isOpen.value = await lucidaoSale.methods.isOpen().call(); }

  return { openingTime, closingTime, isOpen, isClosed, rate, rateFormatted, checkIsOpen };
}
