import { FANTOM_CONNECTION, FANTOM_NETWORK_PARAMS, LCD_INFO } from "@/constants";
import { useStore } from "@/store/store";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { computed } from "vue";
import Web3Modal from "web3modal";

export default function useNetwork() {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        ...FANTOM_CONNECTION,
        qrcode: true,
        pollingInterval: 3000,
      },
    },
  };

  let web3Modal: Web3Modal;

  const store = useStore();

  const initWeb3 = () => {
    if (!web3Modal) {
      web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions,
      });
    }
  };

  const connectToNetwork = async () => {
    let provider: any = null;

    initWeb3();

    try {
      provider = await web3Modal.connect();
      if (provider.isMetaMask) {
        provider
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: FANTOM_NETWORK_PARAMS.chainId }]
          })
          .catch((error: any) => {
            if (error.code == 4902) {
              provider
                .request({
                  method: "wallet_addEthereumChain",
                  params: [FANTOM_NETWORK_PARAMS]
                })
                .catch((error: any) => {
                  console.log(error)
                })
            } else {
              console.log(error);
            }
          })
      }
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      return;
    }

    store.commit("setupWeb3", provider);

    provider.on("accountsChanged", async () => {
      await fetchAccountData();
    });

    provider.on("chainChanged", () => {
      window.location.reload();
    });

    provider.on("networkChanged", async () => {
      await fetchAccountData();
    });

    await fetchAccountData();
  };

  const unwatchChanges = () => {
    store.state.watcher = false;
  }

  const disconnectFromNetwork = () => {
    unwatchChanges();
    initWeb3();
    web3Modal.clearCachedProvider();
    store.commit("setCurrentUser", "");
    store.commit("setChainId", 0);
    store.commit("setupWeb3", null);
  };

  const fetchAccountData = async () => {
    store.commit("setChainId", await store.getters.eth.getChainId());
    const accounts = await store.getters.eth.getAccounts();
    store.commit("setCurrentUser", accounts[0]);
  };

  const tryAutoconnectToNetwork = async () => {
    initWeb3();
    if (web3Modal.cachedProvider) {
      console.log(web3Modal.cachedProvider);
      await connectToNetwork();
    }
  };

  const addLcdToMetamask = async () => {
    try {
      const provider = await detectEthereumProvider({ mustBeMetaMask: true }) as any;
      if (provider) {
        await provider.request({
          method: 'wallet_watchAsset',
          params: { type: 'ERC20', options: LCD_INFO },
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return {
    connectToNetwork,
    disconnectFromNetwork,
    tryAutoconnectToNetwork,
    addLcdToMetamask,
    chainId: computed(() => store.state.chainId),
    currentUser: computed(() => store.state.currentUser),
  };
}
