export const FANTOM_NETWORK_ID = process.env.VUE_APP_FANTOM_NETWORK_ID;
export const FANTOM_CONNECTION = {
  rpc: { [FANTOM_NETWORK_ID as string]: process.env.VUE_APP_FANTOM_RPC },
  network: process.env.VUE_APP_FANTOM_NETWORK_NAME,
};
export const FANTOM_NETWORK_PARAMS = {
  chainId: process.env.VUE_APP_FANTOM_NETWORK_ID_HEX,
  chainName: process.env.VUE_APP_FANTOM_NETWORK_NAME,
  nativeCurrency: {
    name: 'Fantom',
    symbol: 'FTM',
    decimals: 18
  },
  rpcUrls: [process.env.VUE_APP_FANTOM_RPC],
  blockExplorerUrls: [process.env.VUE_APP_FTMSCAN]
}

export const LUCIDAO_SALE_ADDRESS = process.env.VUE_APP_LUCIDAO_SALE_ADDRESS;
export const LUCIDAO_ADDRESS = process.env.VUE_APP_LUCIDAO_ADDRESS;
export const USDT_ADDRESS = process.env.VUE_APP_USDT_TOKEN_ADDRESS;

export const ABI_PATH = "abi/";
export const FTMSCAN = process.env.VUE_APP_FTMSCAN;
export const URLS = {
  ftmscan: `${FTMSCAN}/token/${LUCIDAO_ADDRESS}`,
  blockExplorer: `${FTMSCAN}/block/`,
  sale: `${FTMSCAN}/address/${LUCIDAO_SALE_ADDRESS}`,
  whitepaper: "https://storageapi.fleek.co/51d374ec-06bd-4bc4-b296-40513052fbe0-bucket/lucidao-whitepaper-1.0.pdf"
};

export const LCD_INFO = {
  address: LUCIDAO_ADDRESS,
  symbol: "LCD",
  decimals: 18,
  image: "https://storageapi.fleek.co/51d374ec-06bd-4bc4-b296-40513052fbe0-bucket/lcd-icon.png",
}
